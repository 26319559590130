$("#mfa-change-btn").on('click', captureMfaType);
$("#mfatpyechosen-btn").on('click', mfaTypeChosen);

if($(".requirePick").val() == "True")
{
    captureMfaType();
}

function mfaTypeChosen() {
    $("#mfatypecapture").hide();
    $("#mfaTypeHelper").hide();
    $("#mfaCodeHelper").show();
    $("#mfacapture").show();
    
    
    let typCap = $("#ddlMfaType");
    $("#mfatypehelp").text(typCap.val().toString());
    $("#mfatypelabel").text(typCap.val().toString());
    $("#MFATypeName").val(typCap.val());
    $("#mfa-requestcode-btn").trigger('click');
    
}

function captureMfaType() {
    $("#mfatypecapture").show();
    $("#mfaCodeHelper").hide();
    $("#mfaTypeHelper").show();
    $("#mfacapture").hide();
}