import { applicationApi as api } from './Api';

let emailVerified = false;
let timeout: number = undefined;

function setLoading(loading: boolean) {
  var button = $("#send-email-button");
  var spinner = $("#send-email-spinner");
  if (loading) {
    spinner.show();
    button.attr("disabled", "disabled");
  } else {
    spinner.hide();
    button.removeAttr("disabled");
  }
}

async function ReSendEmail(event: any) {
    event.preventDefault();
    setLoading(true);
    try {
        const emailSent = await api.sendVerificationEmail();
    
        if (emailSent) {
            (window as any).lbr.toast.success("Email sent successfully");

            var getContentUrl = window.location.origin + $("[name='reloadCorrespondanceUrl']").val();
            const tokens: NodeListOf<HTMLInputElement> = document.getElementsByName('__RequestVerificationToken') as any;
            const token = tokens && tokens.length ? tokens[0].value : '';
            let el = document.getElementById("emailLog");
            await fetch(`${getContentUrl}`, {            
                method: "GET",
                credentials: "include",
                headers: { 'Content-Type': 'application/x-www-form-urlencoded'},
            }).then(function (response) {
                if (response.ok) {
                    return response.text();
                }
            }).then(function (newHtmlContent) {
                el.innerHTML = newHtmlContent;
            })
            

        } else {
            (window as any).lbr.toast.error("Email failed.");
        }
        
    } catch (error) {
        console.error(error);
        (window as any).lbr.toast.error("Email failed.");
    }
    setLoading(false);
}

async function checkEmailVerification() {  
    emailVerified = await api.checkVerificationEmail();
    if (emailVerified) {
        $("#send-email-container").hide();
    }
}

function timeoutCheckVerification() {
    if (emailVerified) return;

    timeout = window.setTimeout(() => {
    checkEmailVerification().finally(() => {
        timeoutCheckVerification();
    });
    }, 5000);
}

var container = $("#send-email-container");
if(container.length > 0){
    timeoutCheckVerification();
    $("#send-email-button").on('click', ReSendEmail);
}